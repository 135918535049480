import NavBar from "./NavBar";
import React from "react";

export default function Layout({ children }) {
  return (
    <div className="min-h-screen bg-gray-100 text-black flex flex-col">
      <NavBar />
      {children}
    </div>
  );
}
