import React from "react";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { graphql, Link } from "gatsby";

export interface OtherNode {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      modified: string;
    };
    excerpt: string;
  };
}

const quarters = {
  "1": "Early in",
  "2": "Summer of",
  "3": "Late summer of",
  "4": "Fall",
};

function quarterYearToDesc(s: string) {
  const [quarter, year] = s.split(":", 2);
  return `${quarters[parseInt(quarter)]} '${year}`;
}

export default function Story({ data }) {
  const post = data.markdownRemark;
  const links = data.allMarkdownRemark.edges.map((el: OtherNode) => {
    return (
      <Card>
        <Link
          to={el.node.fields.slug}
          className="flex justify-between items-center"
        >
          <h3>{el.node.frontmatter.title}</h3>
          <span>{quarterYearToDesc(el.node.frontmatter.modified)}</span>
        </Link>
        <span dangerouslySetInnerHTML={{ __html: el.node.excerpt }} />
      </Card>
    );
  });
  const created = post.frontmatter.created;
  const modified = post.frontmatter.modified;
  return (
    <Layout>
      <div className="px-4 mx-4 py-2 my-2 shadow dark:shadow-inner bg-gray-50 rounded flex flex-col gap-y-4">
        <div className="prose max-w-full">
          <article className="lg:prose-xl max-w-prose mx-auto">
            <h1>{post.frontmatter.title}</h1>
            <span>Created on: {created}</span>
            {modified && created != modified && (
              <span>Updated on: {modified}</span>
            )}
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className="container"
            ></div>
          </article>
          <h2 className="text-right">Other Works</h2>
          <div className="grid grid-cols-3 lg:grid-cols-4 gap-4">{links}</div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query(
    $slug: String!
    $format: String = "MMM Do, YYYY @ hh:mm A"
    $f2: String = "Q:YY"
  ) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        created(formatString: $format)
        modified(formatString: $format)
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { slug: { ne: $slug } }
        frontmatter: { title: { ne: "" }, public: { eq: true } }
      }
      sort: { fields: frontmatter___modified, order: DESC }
    ) {
      edges {
        node {
          ...ExcerptFragment
          fields {
            slug
          }
          frontmatter {
            title
            modified(formatString: $f2)
          }
        }
      }
    }
  }
`;
