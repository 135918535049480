import { Link } from "gatsby";
import { toggle } from "nightwind/helper";
import React from "react";

export interface Props {
  title?: string;
}

export default function NavBar(props: Props) {
  return (
    <div className="flex flex-col md:flex-row gap-x-4 gap-y-2 pt-2 pb-4 px-4 items-center pb-3 justify-between sticky top-0 border-black border-b bg-gray-50">
      <div>
        {"title" in props ? (
          <span className="text-3xl">{props.title}</span>
        ) : (
            <Link className="text-3xl" to="/">
            tehblog
          </Link>  
        )}
        <span className="ml-4">A blog about everything and nothing</span>
      </div>
      <nav className="flex flex-wrap text-2xl font-light gap-x-2 items-center">
        <button
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
        >
          Theme
        </button>
      </nav>
    </div>
  );
}
